import React, { useEffect, useRef, useState } from "react";
import { Box, Flex } from "theme-ui";
import useFetch from "use-http";
import AppButton from "./layout/app-button";
import ColumnContainer from "./layout/column-container";

const endpoint = "/.netlify/functions/status";

function fmtMSS(s) {
  return (s - (s %= 60)) / 60 + (9 < s ? ":" : ":0") + s;
}

function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

const BuildIndicator = () => {
  const [canRender, setCanRender] = useState(false);
  useEffect(() => {
    if (document.referrer.includes(process.env.GATSBY_CMS_CLUE)) {
      setCanRender(true);
    }
    if (window.location.hash.includes("build")) {
      setCanRender(true);
    }
  });
  const [buildStarted, setBuildStarted] = useState(false);
  const [buildFinished, setBuildFinished] = useState(false);
  const [secondsElapsed, setSecondsElapsed] = useState(0);
  const [apiData, setApiData] = useState(false);
  let { get, data = {} } = useFetch(
    endpoint,
    { cache: "no-cache", cachePolicy: "no-cache" },
    []
  );
  useInterval(async () => {
    setSecondsElapsed((s) => s + 1);
  }, 1000);
  useInterval(
    async () => {
      if (canRender) {
        const response = await get();
        setApiData(response);
      }
    },
    buildFinished ? null : buildStarted ? 1000 : 3000
  );
  useEffect(() => {
    if (data.status === "ready") {
      buildStarted && setBuildFinished(true);
    }
    if (data.status === "building") {
      setBuildStarted(true);
    }
    if (data.status === "ready") {
      setBuildStarted(false);
    }
  });

  const messages = {
    loading: "Waiting for publish information.",
    error: "We couldn't find any publish information, please try again",
    new: "Fetching new data",
    enqueued: "Starting update server",
    building: "Your update is publishing! Just a moment please.",
    preparing: "Validating updates.",
    prepared: "All updates checked.",
    uploading: "We're uploading to the CDN.",
    processing: "Final adjustments...",
    ready: buildFinished ? "Update is ready." : "No Updates pending.",
  };
  const message = messages[apiData.status] ?? "Waiting...";
  return canRender ? (
    <Box
      sx={{
        py: [4],
        bg: "coral",
        color: "white",
        borderBottom: "5px solid",
        borderBottomColor: "yellow",
      }}
    >
      <ColumnContainer>
        <Flex sx={{ alignItems: "center" }}>
          {buildStarted && <Flex>({fmtMSS(secondsElapsed)}) - </Flex>}
          {message}
          {buildFinished && (
            <Box sx={{ display: "inline", ml: [3, 4] }}>
              <AppButton
                onClick={() => window.location.reload(false)}
                icon="share"
                colors={{
                  default: {
                    background: "purple",
                    text: "white",
                  },
                  hover: { background: "yellow", text: "text" },
                }}
              >
                Refresh
              </AppButton>
            </Box>
          )}
        </Flex>
      </ColumnContainer>
    </Box>
  ) : null;
};

export default BuildIndicator;
