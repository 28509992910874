import React from "react";
import { Box } from "theme-ui";

const ColumnContainer = ({ children }) => {
  return (
    <Box
      sx={{
        // border: "2px solid red",
        // bg: ["coral", "darkRed", "purple", "blue", "darkGreen"],
        width: "100%",
        maxWidth: "column",
        px: [3, 4, 5],
        mx: "auto",
        position: "relative",
      }}
    >
      {children}
    </Box>
  );
};

export default ColumnContainer;
