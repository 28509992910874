export const navItems = [
  {
    label: "Blogs",
    to: "/blogs",
    color: "purple",
  },
  {
    label: "Publications",
    to: "/publications",
    color: "coral",
  },
  {
    label: "Data Stories",
    to: "/data-stories",
    color: "darkGreen",
  },
  {
    label: "Events",
    to: "/events",
    color: "blue",
  },
  {
    label: "About",
    to: "/about",
    color: "yellow",
  },
];
