// example theme
import { base as rootPreset } from "@theme-ui/presets";

const theme = {
  ...rootPreset,
  useLocalStorage: false,
  breakpoints: [
    "@media screen and (min-width: 481px)", // Small Tablet
    "@media screen and (min-width: 769px)", // Tablet
    "@media screen and (min-width: 1024px)", // Large Tablet & Laptop
    "@media screen and (min-width: 1920px)", // Large monitor TV
  ],
  space: [0, 3, 6, 12, 25, 50, 100, 200, 400],
  fontSizes: [8, 10, 12, 16, 20, 24, 32, 42, 54],
  colors: {
    blue: "#2432FF",
    purple: "#4932A9",
    coral: "#FF5A5F",
    yellow: "#FFBA08",
    darkGreen: "#004643",
    darkRed: "#570000",
    blueGray: "#A2AEBB",
    lightGray: "#9B9B9F",
    lighterGray: "#DFDFDF",
    darkGray: "#47464E",
    highlight: "#24242A",
    offWhiteBorder: "#ceced0",
    offWhite: "#f4f4f4",
    white: "#FFFFFF",
    background: "white",
    text: "#24252d",
    hoverText: "white",
  },
  fontWeights: {
    heading: 700,
    body: 400,
  },
  lineHeights: {
    heading: 1.25,
  },
  fonts: {
    body: "Area",
    heading: "Area",
    extended: "Area-Extended",
    ui: "Area",
  },
  text: {
    label: {
      textTransform: "uppercase",
      fontSize: [10, 1, 2],
      fontWeight: 700,
    },
    title: {
      fontSize: [4, null, null, 6],
      lineHeight: [1.3],
      my: [2, 3],
      fontWeight: 700,
      "html.wf-active &": {
        fontWeight: "bold",
      },
    },
    subtitle: {
      fontSize: [2, null, 3, 4],
      lineHeight: 1.5,
      my: [3],
      fontWeight: 400,
      "html.wf-active &": {},
    },
    paragraph: {
      lineHeight: 1.6,
      fontSize: ["14px", 3],
    },
  },
  widths: ["960px"],
  forms: {
    input: {
      border: "none",
      borderBottom: "1px solid",
      borderBottomColor: "darkGray",
      borderRadius: 0,
      pl: 0,
      pb: [2],
    },
  },
  buttons: {
    primary: {
      bg: "transparent",
      transition: "all 0.2s",
      border: 0,
      borderColor: "highlight",
      color: "highlight",
      cursor: "pointer",
      fontFamily: "ui",
      borderRadius: [20, 30],
      mt: 2,
      px: [2, 3],
      py: ["2px", 1],
      "&:hover": {
        bg: "coral",
        color: "white",
        borderColor: "coral",
      },
    },
  },
  sizes: {
    ...rootPreset.sizes,
    column: ["100%", null, null, 1280],
  },
  styles: {
    hr: {
      borderColor: "text",
      ...rootPreset.styles.hr,
      width: "100%",
    },
    ...rootPreset.styles,
    h1: {
      ...rootPreset.styles.h1,
      fontSize: [5, 6],
    },
    h2: {
      ...rootPreset.styles.h2,
      fontSize: [4, 5],
    },
    p: {
      ...rootPreset.styles.p,
      mb: [1, 2],
      fontSize: ["1.125rem", "1.25rem"],
      lineHeight: ["1.5625rem", "1.875rem"],
      maxWidth: "600px",
    },
    root: {
      "h1, h2, h4, h4, p": {
        margin: 0,
      },
      figure: {
        margin: 0,
      },
      a: {
        color: "coral",
        textDecoration: "none",
        cursor: "pointer",
        "&:hover": {
          color: "darkRed",
        },
      },
      body: {
        webkitFontSmoothing: "antialiased",
        fontFamily: "body",
      },
      html: {
        height: "100%",
      },
      "#gatsby-focus-wrapper, #___gatsby, body": {
        minHeight: "100vh",
        overflow: "scroll",
      },
      pre: {
        fontSize: [1, 2],
        lineHeight: 1.4,
        padding: [3, 4],
      },
      li: {
        mb: [3],
      },
      ul: {
        paddingLeft: [4],
      },
      ol: {
        paddingLeft: [4],
      },
    },
  },
};

export default theme;
