import Link from "gatsby-link";
import React from "react";
import { Box, Flex, Grid } from "theme-ui";
import footerLogo from "../images/footer-logo.png";
import { navItems } from "../nav-config.js";
import ColumnContainer from "./layout/column-container.js";

const infoItems = [
  // {
  //   label: "FAQs",
  //   to: "/faqs",
  // },
  {
    label: "Privacy Policy",
    to: "/privacy-policy",
  },
  // {
  //   label: "Terms of Use",
  //   to: "/terms-and-conditions",
  // },
];

const Footer = () => (
  <ColumnContainer>
    <Box
      className="hide-on-pdf"
      as="footer"
      sx={{
        mt: 5,
        mb: [5, 6, 7],
        px: [3],
      }}
    >
      <Grid gap={[4, 5]} columns={[1, 1, 2]}>
        {/* Page directory */}
        <Grid gap={[4, 5]} columns={[3, 3]}>
          <Box
            sx={{
              order: [1, 1],
            }}
          >
            <FooterTitle>Pages</FooterTitle>
            {navItems.map((item) => (
              <FooterLink key={item.to} to={item.to}>
                {item.label}
              </FooterLink>
            ))}
          </Box>
          <Box
            sx={{
              order: [1, 1],
            }}
          >
            <FooterTitle>Info</FooterTitle>
            {infoItems.map((item) => (
              <FooterLink key={item.to} to={item.to}>
                {item.label}
              </FooterLink>
            ))}
          </Box>
          <Box sx={{ order: [3, 3] }}>
            <FooterTitle>Contact</FooterTitle>
            <FooterLink href="mailto:info@aqi.org.uk">
              info@aqi.org.uk
            </FooterLink>
            <FooterLink href="https://twitter.com/intent/user?screen_name=AQ_insight">
              Follow on Twitter
            </FooterLink>
          </Box>
        </Grid>

        {/* Form and Logo */}
        <Grid gap={[4, 1]} columns={[1, 2]}>
          <div />
          {/* <Box sx={{}}>
            <FooterTitle>Join</FooterTitle>
            <Form />
          </Box> */}

          <Flex>
            <img
              sx={{
                float: "right",
                maxWidth: ["50%", "100%"],
                height: "auto",
                paddingLeft: [null, 3],
                alignSelf: [null, "flex-end", "flex-start"],
              }}
              alt="Hosted and Curated by AQA with AQA logo"
              src={footerLogo}
            />
          </Flex>
        </Grid>
      </Grid>
      <Flex
        sx={{
          my: 4,
          mt: [4, 5, 6],
          fontSize: [2],
        }}
      >
        <div sx={{}} />© {new Date().getFullYear()} AQA
      </Flex>
    </Box>
  </ColumnContainer>
);

const FooterTitle = ({ children }) => (
  <Box
    sx={{
      fontSize: [2],
      fontWeight: "bold",
      textTransform: "uppercase",
      mb: [3, 4],
    }}
  >
    {children}
  </Box>
);

const FooterLink = ({ to, href, children }) => (
  <Box
    sx={{ fontSize: [2], color: "darkGray", display: "block", mb: [2] }}
    as={to ? Link : "a"}
    to={to ? to : null}
    href={href ? href : null}
  >
    {children}
  </Box>
);

export default Footer;
