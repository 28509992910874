import React from "react";
import { Box, Button } from "theme-ui";
import { Link } from "gatsby";
import ArrowDown from "../../images/icons/arrow-down.svg";
import ArrowUp from "../../images/icons/arrow-up.svg";
import ArrowLeft from "../../images/icons/arrow-left.svg";
import ArrowRight from "../../images/icons/arrow-right.svg";
import Cal from "../../images/icons/cal.svg";
import Chart from "../../images/icons/chart.svg";
import Filter from "../../images/icons/filter.svg";
import MapMarker from "../../images/icons/map-marker.svg";
import Mic from "../../images/icons/mic.svg";
import Minus from "../../images/icons/minus.svg";
import Plus from "../../images/icons/plus.svg";
import Report from "../../images/icons/report.svg";
import Share from "../../images/icons/share.svg";
import Index from "../../images/icons/index.svg";

const getIcon = (iconName) => {
  const icons = {
    arrowUp: ArrowUp,
    arrowDown: ArrowDown,
    arrowLeft: ArrowLeft,
    arrowRight: ArrowRight,
    cal: Cal,
    chart: Chart,
    filter: Filter,
    mapMarker: MapMarker,
    mic: Mic,
    minus: Minus,
    plus: Plus,
    report: Report,
    share: Share,
    index: Index,
  };
  return icons[iconName] ?? null;
};

const AppButton = ({
  icon = false,
  color = "coral",
  colors,
  children,
  to,
  ...rest
}) => {
  const Icon = getIcon(icon);
  return (
    <Button
      {...rest}
      as={to ? Link : "button"}
      to={to}
      sx={{
        "&:visited": { backgroundColor: "lighterGray", color: "highlight" },
        width: "max-content",
        bg: colors?.default?.background,
        color: colors?.default?.text,
        position: "relative",
        "&:hover": {
          bg: colors?.hover?.background,
          color: colors?.hover?.text,
        },
        "& .colorable path, & .colorable": {
          fill: colors?.default?.iconColor || colors?.default?.background,
        },
        "&:hover .colorable path, & .colorable": {
          fill: colors?.hover?.iconColor || colors?.hover?.background,
        },
      }}
    >
      <span
        sx={{
          ml: 2,
          mr: Icon ? null : 2,
          top: Icon ? ["-3px", "-4px"] : null,
          position: "relative",
        }}
      >
        {children}
      </span>
      {Icon && (
        <Box
          sx={{
            ml: "10px",
            mr: 2,
            height: "auto",
            width: [20, 24],
            my: "2px",
            top: "3px",
            position: "relative",
          }}
          as={Icon}
        />
      )}
    </Button>
  );
};

export default AppButton;
