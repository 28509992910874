import React, { useEffect } from "react";
import { Input, Text, Grid, Box, Flex, Button } from "@theme-ui/components";
import { useThemeUI } from "@theme-ui/core";
import Logo from "../../images/svgs/aqi-logo.svg";
import Link from "gatsby-link";
import { navItems } from "../../nav-config";

const MobileNavButton = ({ color = "white" }) => {
  const barStyle = {
    bg: color,
    content: '" "',
    display: "block",
    width: "30px",
    height: "2px",
    margin: "auto",
    transition: "all 0.2s ease-out",
  };
  return (
    <Input
      className="hide-on-pdf"
      tabIndex={0}
      type="checkbox"
      sx={{
        display: [null, null, "none"],
        height: 32,
        width: 32,
        background: "transparent",
        margin: "auto",
        marginTop: [2],
        marginBottom: [-2],
        border: 0,
        transition: "rotate translate 0.2s ease-out",
        transform: "translate(0px, 2px)",
        zIndex: 100,
        "& ~ div": {
          opacity: [0, 0, 1],
          pointerEvents: ["none", null, "all"],
        },
        "&:checked ~ div": {
          opacity: 1,
          pointerEvents: "all",
        },
        "&:checked": {
          transform: "transform(0px, 0px)",
          position: "fixed",
          left: "calc(50% - 16px)",
          top: 10,
        },
        "&::before": {
          ...barStyle,
          transformOrigin: "center center",
        },
        "&::after": {
          ...barStyle,
          transformOrigin: "center center",
          marginTop: 10,
        },
        "&:checked::before": {
          transform: "rotate(45deg) translate(9px, 8px)",
        },
        "&:checked::after": {
          transform: "rotate(-45deg) translate(0px, 0px)",
        },
      }}
    />
  );
};

const NavLink = ({
  children,
  to,
  active = false,
  textColor,
  noneActive = "false",
}) => (
  <Box
    as={Link}
    sx={{
      transition: "border-color 0.3s linear",
      fontFamily: ["extended", null, "heading"],
      fontWeight: 100,
      WebkitFontSmoothing: "antialiased",
      color: textColor,
      opacity: noneActive ? 1 : active ? 1 : [0.5, null, 1],
      m: [0, 0, 3],
      fontSize: [6, 7, 3],
      pb: [2],
      mb: 2,
      mr: [null, null, 0],
      ml: [null, null, 4],
      whiteSpace: "nowrap",
      borderBottom: active ? "1px solid" : "1px solid transparent",
      borderBottomColor: active
        ? ["transparent", null, textColor]
        : "transparent",
      "&: hover, &:focus": {
        color: textColor,
        borderBottom: [null, null, "1px solid"],
        opacity: 1,
      },
    }}
    to={to}
  >
    {children}
  </Box>
);

const LogoButton = ({ color }) => (
  <Flex as={Link} to="/" sx={{ my: [4] }}>
    <Logo
      sx={{
        minWidth: 0,
        width: [74, 80],
        height: [30, 44, 42],
        mr: [3, 4],
        flexShrink: 0,
        "& path": {
          fill: `${color}`,
        },
      }}
    />
    <h3
      sx={{
        fontFamily: "heading",
        fontWeight: "body",
        fontSize: [2, 3],
        lineHeight: 1.4,
        py: 0,
        my: 0,
        color: color,
      }}
    >
      <Flex
        sx={{
          minWidth: 0,
          minHeight: 0,
          flexDirection: "column",
          whiteSpace: "nowrap",
        }}
      >
        <Box>Home of Assessment</Box>
        <Box>& Qualifications Insight</Box>
      </Flex>
    </h3>
  </Flex>
);

const ThemeableHeader = ({
  title = "Welcome to AQi",
  subtitle = false,
  pageIntroduction = false,
  link = false,
  active,
  backgroundImage,
  theme,
  color = "text",
  themeLink,
}) => {
  const themeData = useThemeUI();
  let colorForThemeColorMetaTag;
  let textColor = "white";
  // Check if we have an active nav item to get a color from?
  const navColor = navItems.filter((item) => item.to === active)[0]?.color;
  // Otherwise use the color from the color prop
  const bgColor = navColor ? navColor : color;
  if (bgColor === "yellow") {
    textColor = "text";
  }
  colorForThemeColorMetaTag = backgroundImage
    ? "purple"
    : navColor
    ? navColor
    : color;
  useEffect(() => {
    var theme = document.querySelector('meta[name="theme-color"]');
    theme.setAttribute(
      "content",
      themeData.theme.rawColors[colorForThemeColorMetaTag]
    );
  }, [themeData.theme.rawColors, colorForThemeColorMetaTag]);
  return (
    <>
      <Box
        id="back-to-top"
        as="section"
        sx={{
          mx: "auto",
          bg: backgroundImage ? null : bgColor,
          backgroundImage: backgroundImage ? `url(${backgroundImage})` : null,
          backgroundSize: "cover",
          backgroundPosition: "center center",
          transition: "background 0.3s linear, height 0.3s linear",
          color: textColor,
          borderRadius: ["0 0 30px 30px", "0 0 50px 50px"],
          overflow: "hidden",
          position: "relative",
        }}
      >
        <Box
          sx={{
            bg: backgroundImage ? color : null,
            position: "absolute",
            top: 0,
            left: 0,
            height: "100%",
            width: "100%",
            opacity: 0.87,
            zIndex: 0,
          }}
        />

        <Box
          sx={{
            maxWidth: "column",
            mx: "auto",
            mw: 0,
            pt: [3, 4],
            px: [4, 5],
            zIndex: 20,
            position: "relative",
          }}
        >
          <Flex sx={{ minWidth: 0, justifyContent: "space-between" }}>
            <Flex
              as="nav"
              sx={{
                flexDirection: ["column", null, "row"],
                justifyContent: ["flex-end", null, "space-between"],
                top: [0],
                left: [0],
                width: ["100%"],
                borderRadius: ["0 0 30px 30px"],
                zIndex: 200,
                position: "sticky",
              }}
            >
              <MobileNavButton color={textColor} />
              <LogoButton color={textColor} />
              <Flex
                className="hide-on-pdf"
                sx={{
                  background: ["rgba(255,255,255,0.9)", null, "transparent"],
                  p: [0],
                  position: ["fixed", null, "relative"],
                  top: 0,
                  right: 0,
                  height: "100%",
                  width: ["100%", null, "auto"],
                  py: [4],
                  pt: [7, null, 4],
                  transition: "opacity 0.3s linear",
                }}
              >
                <Flex
                  as="ul"
                  sx={{
                    flexDirection: ["column", null, "row"],
                    bg: [bgColor, null, "transparent"],
                    borderRadius: "0 0 30px 30px",
                    top: 0,
                    position: ["absolute", null, "relative"],
                    width: ["100%", null, "auto"],
                    marginLeft: [0, null, 0],
                    p: [4, 5, 0],
                    pt: [6, 6, 2],
                    justifyContent: "flex-end",
                  }}
                >
                  {navItems.map((item) => (
                    <li key={item.to} sx={{ listStyle: "none", mb: 0 }}>
                      <NavLink
                        active={item.to === active}
                        to={item.to}
                        textColor={textColor}
                        noneActive={!navColor}
                      >
                        {item.label}
                      </NavLink>
                    </li>
                  ))}
                </Flex>
              </Flex>
            </Flex>
          </Flex>
          <Grid
            columns={1}
            gap={[3, 4, 5]}
            sx={{
              mb: [4, 5],
              mt: backgroundImage ? ["25vh", null, "20vh"] : [5],
            }}
          >
            {theme && (
              <Text
                as="p"
                variant="label"
                sx={{ color: textColor, mb: [1, "-100%"] }}
              >
                {theme}
              </Text>
            )}
            {title && (
              <Text
                as="h1"
                sx={{
                  fontFamily: "extended",
                  WebkitFontSmoothing: "antialiased",
                  fontWeight: "body",
                  fontSize: [6, 7, 8],
                  lineHeight: 1.2,
                }}
              >
                {title}
              </Text>
            )}
            {subtitle && (
              <Text
                as="p"
                variant="paragraph"
                sx={{
                  fontSize: [3, 4, 5],
                  maxWidth: 700,
                }}
              >
                {subtitle}
              </Text>
            )}
            {pageIntroduction && (
              <Text
                as="p"
                variant="paragraph"
                sx={{
                  maxWidth: 700,
                }}
              >
                {pageIntroduction}
              </Text>
            )}
            {link && (
              <Box>
                <Button
                  sx={{
                    bg: "coral",
                    color: "white",
                    alignSelf: "flex-start",
                    borderColor: "coral",
                  }}
                  as={Link}
                  to={link.to}
                >
                  {link.label}
                </Button>
              </Box>
            )}
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default ThemeableHeader;
