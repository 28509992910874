/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import { css, Global } from "@emotion/react";
import PropTypes from "prop-types";
import React from "react";
import { ThemeProvider } from "theme-ui";
import cssReset from "../styles/reset";
import siteTheme from "../styles/theme";
import Footer from "./footer";
import BuildIndicator from "./build-indicator";

const Layout = ({ children }) => {
  return (
    <ThemeProvider theme={siteTheme}>
      <Global styles={cssReset} />
      <Global
        styles={(theme) => css`
          body {
            font-family: ${theme.fonts.body}, Helvetica, sans-serif;
            line-height: 1.5;
          }
          html {
            height: 100%;
          }
          body,
          #___gatsby,
          #gatsby-focus-wrapper {
            min-height: 100vh;
            overflow: scroll;
          }
        `}
      />
      <BuildIndicator />
      <div
        sx={{
          mx: "auto",
          minHeight: "100vh",
          overflow: "scroll",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <main>{children}</main>
        <Footer />
      </div>
    </ThemeProvider>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
